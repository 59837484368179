import {
  EnumLoyaltiProgramChannel,
  EnumExtraMultiplierProductType,
} from '@/app/infrastructures/misc/Constants/loyaltyProgram'
import { LevelInfoPayload } from '@/app/ui/controllers/MembershipFormulaController'
import {
  CreateExtraMultiplierRequestInterface,
  CreateLoyaltyProgramMembershipFormulaInterface,
  UpdateExtraMultiplierRequestInterface,
  UpdateMembershipFormulaRequestInterface,
} from '../contracts/MembershipFormulaRequest'

export interface MembershipFormulaLevelInfo {
  id?: number
  multiplier?: number
}

export interface ExtraMultiplierInfoInterface {
  id: string | number
  extraMultiplier: string | number
}

export class UpdateMembershipFormulaApiRequest
  implements UpdateMembershipFormulaRequestInterface {
  private channel?: string
  private nominal?: number | string
  private is_sender?: boolean
  private is_receiver?: boolean
  private level_info?: Array<LevelInfoPayload>
  private start_date?: string
  private end_date?: string

  constructor(
    channel?: string,
    nominal?: number | string,
    is_sender?: boolean,
    is_receiver?: boolean,
    level_info?: Array<LevelInfoPayload>,
    start_date?: string,
    end_date?: string
  ) {
    this.channel = channel
    this.nominal = nominal
    this.is_sender = is_sender
    this.is_receiver = is_receiver
    this.level_info = level_info
    this.start_date = start_date
    this.end_date = end_date
  }

  public toPayload(): string {
    return JSON.stringify({
      channel: this.channel,
      nominal: this.nominal,
      is_sender: this.is_sender,
      is_receiver: this.is_receiver,
      level_info: this.level_info,
      start_date: this.start_date,
      end_date: this.end_date,
    })
  }
}

export class CreateLoyaltyProgramMembershipFormula
  implements CreateLoyaltyProgramMembershipFormulaInterface {
  private readonly channel?: string
  private readonly nominal?: number | string
  private readonly is_sender?: boolean
  private readonly is_receiver?: boolean
  private readonly level_info?: Array<MembershipFormulaLevelInfo>
  private readonly start_date?: string
  private readonly end_date?: string

  constructor(
    channel?: string,
    nominal?: number | string,
    isSender?: boolean,
    isReceiver?: boolean,
    levelInfo?: Array<MembershipFormulaLevelInfo>,
    startDate?: string,
    endDate?: string
  ) {
    this.channel = channel
    this.nominal = nominal
    this.is_sender = isSender
    this.is_receiver = isReceiver
    this.level_info = levelInfo
    this.start_date = startDate
    this.end_date = endDate
  }
  toPayload(): string {
    return JSON.stringify({
      channel: this.channel,
      nominal: this.nominal,
      is_sender: this.is_sender,
      is_receiver: this.is_receiver,
      level_info: this.level_info,
      start_date: this.start_date,
      end_date: this.end_date,
    })
  }
}

export class CreateExtraMultiplierRequest
  implements CreateExtraMultiplierRequestInterface {
  private channel: EnumLoyaltiProgramChannel
  private productType: EnumExtraMultiplierProductType
  private extraMultiplierInfo: Array<ExtraMultiplierInfoInterface>

  constructor(
    channel: EnumLoyaltiProgramChannel,
    productType: EnumExtraMultiplierProductType,
    extraMultiplierInfo: Array<ExtraMultiplierInfoInterface>
  ) {
    this.channel = channel
    this.productType = productType
    this.extraMultiplierInfo = extraMultiplierInfo
  }

  toPayload(): string {
    return JSON.stringify({
      channel: this.channel,
      product_type: this.productType,
      extra_multiplier_info: this.extraMultiplierInfo.map(item => ({
        id: item.id,
        extra_multiplier: item.extraMultiplier,
      })),
    })
  }
}

export class UpdateExtraMultiplierRequest extends CreateExtraMultiplierRequest
  implements UpdateExtraMultiplierRequestInterface {
  private id: number

  constructor(
    id: number,
    channel: EnumLoyaltiProgramChannel,
    productType: EnumExtraMultiplierProductType,
    extraMultiplierInfo: Array<ExtraMultiplierInfoInterface>
  ) {
    super(channel, productType, extraMultiplierInfo)
    this.id = id
  }

  toPayload(): string {
    return JSON.stringify({
      extra_multiplier_id: this.id,
      channel: JSON.parse(super.toPayload()).channel,
      product_type: JSON.parse(super.toPayload()).product_type,
      extra_multiplier_info: JSON.parse(super.toPayload())
        .extra_multiplier_info,
    })
  }
}
