import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { Pagination } from '@/domain/entities/Pagination'
import {
  DataExtraMultiplier,
  ListMembershipFormula,
  ListMultiplierLevel,
  MembershipFormula,
  MultiplierLevel,
} from '@/domain/entities/MembershipFormula'
import { MembershipFormulaPresenter } from '../presenters/MembershipFormulaPresenter'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  CreateExtraMultiplierRequest,
  CreateLoyaltyProgramMembershipFormula,
  ExtraMultiplierInfoInterface,
  UpdateExtraMultiplierRequest,
  UpdateMembershipFormulaApiRequest,
} from '@/data/payload/api/MembershipFormulaRequest'
import {
  EnumLoyaltiProgramChannel,
  EnumExtraMultiplierProductType,
} from '@/app/infrastructures/misc/Constants/loyaltyProgram'

export interface MembershipFormulaPayload {
  id: string
  channel: string
  nominal: number | string
  isSender: boolean
  isReceiver: boolean
  levelInfo: Array<LevelInfoPayload>
  startDate: string
  endDate: string
}

export interface LevelInfoPayload {
  id?: number
  multiplier?: number
}

export interface MembershipFormulaState {
  isLoading: boolean
  dataMembershipFormula: MembershipFormula
  listMultiplierLevel: ListMultiplierLevel
  paginationData: Pagination
  errCreate: string
  errUpdate: string
  isDeleteLoyaltyProgramMembershipFormulaSuccess: boolean
  statusCreateExtraMultiplier: string
  dataExtraMultiplier: Array<DataExtraMultiplier>
  statusDeleteExtraMultiplier: string
  detailExtraMultiplier: DataExtraMultiplier
}

export interface CreateExtraMultiplierPayload {
  channel: EnumLoyaltiProgramChannel
  productType: EnumExtraMultiplierProductType
  extraMultiplierInfo: Array<ExtraMultiplierInfoInterface>
}

export interface UpdateExtraMultiplierPayload
  extends CreateExtraMultiplierPayload {
  id: number
}

export interface ParamsExtraMultiplierInterface {
  page: number | string
  perPage: number | string
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'membership-formula',
})
class MembershipFormulaController extends VuexModule
  implements MembershipFormulaState {
  private presenter: MembershipFormulaPresenter = container.resolve(
    MembershipFormulaPresenter
  )
  public isLoading = false
  public listDataMembershipFormula = new ListMembershipFormula()
  public dataMembershipFormula = new MembershipFormula()
  public listMultiplierLevel = new ListMultiplierLevel()
  public paginationData: Pagination = new Pagination()
  public errCreate = ''
  public errUpdate = ''
  public isDeleteLoyaltyProgramMembershipFormulaSuccess = false
  public statusCreateExtraMultiplier = ''
  public dataExtraMultiplier: Array<DataExtraMultiplier> = [
    new DataExtraMultiplier(),
  ]
  public statusDeleteExtraMultiplier = ''
  public detailExtraMultiplier: DataExtraMultiplier = new DataExtraMultiplier()
  public statusUpdateExtraMultiplier = ''

  @Action({ rawError: true })
  getAll(params: Record<string, number | string>): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )
    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setDataListMembershipFormula(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch All Loyalty Program Membership Formula',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDetail(params: Record<string, string | number>): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .get(formattedParams)
      .then(res => {
        this.setDataMembershipFormulaCaptain(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail Membership Formula Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  delete(id: number | string): void {
    this.setLoading(true)
    this.presenter
      .delete(id)
      .then(() => {
        this.setIsDeleteLoyaltyProgramMembershipFormulaSuccess(true)
        Vue.notify({
          title: 'Delete Membership Formula',
          text: 'Delete Loyalty Program Membership Formula Successfully',
          type: 'success',
          duration: 5000,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete Membership Formula',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  getMultiplierLevel(): void {
    this.setLoading(true)

    this.presenter
      .getMultiplier()
      .then(resp => {
        this.setListMultiplier({
          data: <MultiplierLevel[]>resp,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Multiplier Level Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  create(payload: Omit<MembershipFormulaPayload, 'id'>): boolean {
    this.setLoading(true)
    this.presenter
      .create(
        new CreateLoyaltyProgramMembershipFormula(
          payload.channel,
          payload.nominal,
          payload.isSender,
          payload.isReceiver,
          payload.levelInfo,
          payload.startDate,
          payload.endDate
        )
      )
      .then(() => {
        this.setErrCreate(
          EventBusConstants.CREATE_LOYALTY_PROGRAM_MEMBERSHIP_FORMULA
        )
      })
      .catch(error => {
        const errorMessage = [400, 422].includes(error.status)
          ? error.error.message.en
          : 'Something wrong'
        this.setErrCreate(errorMessage)
        Vue.notify({
          title: 'Failed Create Membership Formula',
          text: errorMessage,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })

    return true
  }

  @Action({ rawError: true })
  public update(payload: MembershipFormulaPayload): void {
    this.setLoading(true)
    this.presenter
      .update(
        payload.id,
        new UpdateMembershipFormulaApiRequest(
          payload.channel,
          payload.nominal,
          payload.isSender,
          payload.isReceiver,
          payload.levelInfo,
          payload.startDate,
          payload.endDate
        )
      )
      .then(() => {
        this.setErrUpdate(
          EventBusConstants.UPDATE_LOYALTY_PROGRAM_MEMBERSHIP_FORMULA
        )
        Vue.notify({
          title: 'Update Membership Formula Success',
          text: 'The Formula has been updated',
          type: 'success',
          duration: 5000,
        })
      })
      .catch(error => {
        const errorMessage = [400, 422].includes(error.status)
          ? error.error.message.en
          : 'Something wrong'
        this.setErrUpdate(errorMessage)
        Vue.notify({
          title: 'Update Membership Formula Failed',
          text: errorMessage,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public createExtraMultiplier(payload: CreateExtraMultiplierPayload): void {
    this.setLoading(true)

    this.presenter
      .createExtraMultiplier(
        new CreateExtraMultiplierRequest(
          payload.channel,
          payload.productType,
          payload.extraMultiplierInfo
        )
      )
      .then(() => {
        this.setStatusCreateExtraMultiplier(
          EventBusConstants.CREATE_EXTRA_MULTIPLIER_SUCCESS
        )
      })
      .catch(error => {
        const errorMessage = [400, 422].includes(error.status)
          ? error.error.message.en
          : 'Something wrong'
        this.setErrUpdate(errorMessage)
        Vue.notify({
          title: 'Failed to create extra multiplier',
          text: errorMessage,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getListExtraMultiplier(params: ParamsExtraMultiplierInterface): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getListExtraMultiplier(formattedParams)
      .then(resp => {
        this.setListExtraMultiplier(resp)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail Membership Formula Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public deleteExtraMultiplier(id: string): void {
    this.setLoading(true)

    this.presenter
      .deleteExtraMultiplier(id)
      .then(() => {
        this.setStatusDeleteExtraMultiplier(
          EventBusConstants.DELETE_EXTRA_MULTIPLIER_SUCCESS
        )
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete Extra Multiplier Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDetailExtraMultiplier(id: string): void {
    this.setLoading(true)

    this.presenter
      .getDetailExtraMultiplier(id)
      .then(resp => {
        this.setDetailExtraMultiplier(resp)
      })
      .catch(error => {
        Vue.notify({
          title: 'Get Detail Extra Multiplier Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateExtraMultiplier(payload: UpdateExtraMultiplierPayload): void {
    this.setLoading(true)

    this.presenter
      .updateExtraMultiplier(
        new UpdateExtraMultiplierRequest(
          payload.id,
          payload.channel,
          payload.productType,
          payload.extraMultiplierInfo
        )
      )
      .then(() => {
        this.setStatusUpdateExtraMultiplier(
          EventBusConstants.UPDATE_EXTRA_MULTIPLIER_SUCCESS
        )
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Extra Multiplier Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setDataListMembershipFormula(data: ListMembershipFormula): void {
    this.listDataMembershipFormula = data
  }

  @Mutation
  private setDataMembershipFormulaCaptain(data: MembershipFormula): void {
    this.dataMembershipFormula = data
  }

  @Mutation
  private setListMultiplier(data: ListMultiplierLevel): void {
    this.listMultiplierLevel = data
  }

  @Mutation
  public setErrCreate(error: string): void {
    this.errCreate = error
  }

  @Mutation
  public setErrUpdate(error: string): void {
    this.errUpdate = error
  }

  @Mutation
  public setIsDeleteLoyaltyProgramMembershipFormulaSuccess(
    bool: boolean
  ): void {
    this.isDeleteLoyaltyProgramMembershipFormulaSuccess = bool
  }

  @Mutation
  public setStatusCreateExtraMultiplier(status: string): void {
    this.statusCreateExtraMultiplier = status
  }

  @Mutation
  public setListExtraMultiplier(list: {
    data: Array<DataExtraMultiplier>
    pagination: Pagination
  }): void {
    this.dataExtraMultiplier = list.data
    this.paginationData = list.pagination
  }

  @Mutation
  public setStatusDeleteExtraMultiplier(status: string): void {
    this.statusDeleteExtraMultiplier = status
  }

  @Mutation
  public setDetailExtraMultiplier(data: DataExtraMultiplier): void {
    this.detailExtraMultiplier = data
  }

  @Mutation
  public setStatusUpdateExtraMultiplier(status: string): void {
    this.statusUpdateExtraMultiplier = status
  }
}

export default getModule(MembershipFormulaController)
