function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loyalty-program"},[(_vm.controller.isLoading)?_c('LoadingOverlay'):_c('div',{staticClass:"relative flex flex-row items-end"},[_c('Button',{staticClass:"button-create-formula",attrs:{"disabled":_vm.isGuest,"customPadding":true},on:{"click":function($event){return _vm.$router.push('/loyalty-program/membership-poin-formula/create')}}},[_c('AddCircle'),_c('span',{staticClass:"typography-base"},[_vm._v("Create New")])],1),_c('div',{staticClass:"w-full"},[_c('span',{staticClass:"font-bold text-bgMatterhorn text-2xl"},[_vm._v(" Membership Poin Formula ")]),_c('ModalAction',{attrs:{"visible":_vm.showModal,"icon":"delete","textPrimary":"Delete","textSecondary":"Cancel","title":"Are you sure delete formula?","description":"Data can’t be restore"},on:{"cancel":function($event){return _vm.onModalAction(_vm.enumAction.CANCEL)},"action":function($event){return _vm.onModalAction(_vm.enumAction.DELETE)}}}),_c('div',{staticClass:"w-full overflow-x-auto mt-8"},[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.sliceData(_vm.parameters.page, _vm.parameters.perPage)},scopedSlots:_vm._u([{key:"6",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{class:[
                'flex gap-4 typography-base',
                props.data.value.status !== 'Expired'
                  ? 'text-bgPrimary cursor-pointer'
                  : 'text-bgMatterhorn' ]},[_c('span',{attrs:{"data-testid":("loyalty-program-membership-poin-formula__button-edit-" + (props.index))},on:{"click":function () { return _vm.onActionTable(props.data.value, _vm.enumAction.EDIT); }}},[_vm._v("Edit")])])]}},{key:"7",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var props = rest;
return [_c('div',{class:[
                'flex gap-4 typography-base',
                props.data.value.status === 'Upcoming'
                  ? 'text-bgPrimary cursor-pointer'
                  : 'text-bgMatterhorn' ]},[_c('span',{attrs:{"data-testid":("loyalty-program-membership-poin-formula__button-delete-" + (props.index))},on:{"click":function () { return _vm.onActionTable(props.data.value, _vm.enumAction.DELETE); }}},[_vm._v("Delete")])])]}}])})],1)],1)],1),_c('PaginationNav',{attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"totalItem":_vm.data.length},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }