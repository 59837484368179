






















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import AddCircle from '@/app/ui/assets/add_circle.vue'
import { Utils } from '@/app/infrastructures/misc'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import Modal from '@/app/ui/components/Modal/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import IllutrationDelete from '@/app/ui/assets/trash_icon_alt2.vue'
import { EnumAction } from '@/app/infrastructures/misc/Constants/loyaltyProgram'
import controller from '@/app/ui/controllers/MembershipFormulaController'
import { ListMembershipFormula, MembershipFormula } from '@/domain/entities/MembershipFormula'

interface TypeCustomStyle {
  minWidth?: string
  maxWidth?: string
}

interface status {
  id?: number
  status?: string
}

interface TypeDataTable {
  customStyle?: TypeCustomStyle
  value?: string | number | status
}

@Component({
  components: {
    Button,
    AddCircle,
    DataTableV2,
    LoadingOverlay,
    PaginationNav,
    Modal,
    ModalAction,
    IllutrationDelete,
  }
})

export default class LoyaltyProgram extends Vue {
  isGuest = Utils.isGuest()
  enumAction = EnumAction
  channel = ['Market Place', 'Retail', 'Customer Apps']
  data: Array<TypeDataTable[]> = []
  showModal = false
  deletedId: number | string = ''
  controller = controller
  levelLoyalty: string[] = [
    'rakyat',
    'agan',
    'juragan',
    'sultan',
    'sultanJagat',
  ]
  params: Record<string, number | string> = {
    membershipPointFormulaId: ''
  }
  parameters = {
    page: 1,
    perPage: 10,
  }
  headers = [
    {
      title: 'No',
      customStyle: { minWidth: '77px', maxWidth: '77px' },
    },
    {
      title: 'Channel',
      customStyle: { minWidth: '160px', maxWidth: '100%' },
    },
    {
      title: 'Nominal',
      customStyle: { minWidth: '120px', maxWidth: '100%' },
    },
    {
      title: 'Multiplier',
      customStyle: { minWidth: '170px', maxWidth: '100%' },
    },
    {
      title: 'Start Date',
      customStyle: { minWidth: '172px', maxWidth: '100%' },
    },
    {
      title: 'End Date',
      customStyle: { minWidth: '172px', maxWidth: '100%' },
    },
    {
      title: 'Action',
      customStyle: { minWidth: '124px', maxWidth: '124px' },
    },
  ]

  created(): void {
    this.controller.getAll(this.params)
  }

  private setDataTable(value: MembershipFormula[]): void {
    this.data = value.map((item, index) => [
      {
        value: index + 1,
        customStyle: { minWidth: '77px', maxWidth: '77px' },
      },
      {
        value: item.channel ? item.channel : '',
        customStyle: { minWidth: '160px', maxWidth: '100%' },
      },
      {
        value: item.nominal ? this.formatCurrency(item.nominal) : this.formatCurrency(0),
        customStyle: { minWidth: '120px', maxWidth: '100%' },
      },
      {
        value: this.formatMultiplier(String(item?.levelInfo)),
        customStyle: { minWidth: '170px', maxWidth: '100%' },
      },
      {
        value: item.startDate ? this.formatDate(String(item.startDate)) : '',
        customStyle: { minWidth: '172px', maxWidth: '100%' },
      },
      {
        value: item.endDate ? this.formatDate(String(item?.endDate)) : '',
        customStyle: { minWidth: '172px', maxWidth: '100%' },
      },
      {
        value: {
          id: item?.membershipPointFormulaId,
          status: item?.status,
        },
        customStyle: { minWidth: 'auto', maxWidth: '62px' },
      },
      {
        value: {
          id: item?.membershipPointFormulaId,
          status: item?.status,
        },
        customStyle: { minWidth: 'auto', maxWidth: '62px' },
      },
    ])
  }

  private formatCurrency(number: number): string | number {
    return Utils.currencyDigit(number)
  }

  private formatDate(date: string): string {
    return dayjs(date)
      .locale(dayjsID)
      .format('DD/MM/YYYY - HH:mm')
  }

  private formatMultiplier(item: string): string {
    return String(
      this.levelLoyalty
        .map((level: string, index) => {
          const thepoint = JSON.parse(item.replace('/', ''))[index]
          return `[${ thepoint?.multiplier || 0}]`
        })
        .join(' ')
    )
  }

  private sliceData(page: number, perPage: number): Array<TypeDataTable[]> {
    let start = page === 1 ? 0 : (page - 1) * perPage
    let end = page === 1 ? perPage : start + perPage
    const result = this.data.slice(start, end)
    if (result.length === perPage) return result
    else return this.data.slice(end - perPage)
  }

  private onActionTable(
    membership: Record<string, string>,
    type: string
  ): void {
    if (type === 'DELETE' && membership.status === 'Upcoming') {
      this.deletedId = membership.id
      this.showModal = true
    }
    if (type === 'EDIT' && membership.status !== 'Expired') {
      this.$router.push(
        `/loyalty-program/membership-poin-formula/edit/${membership.id}`
      )
    }
  }

  private onModalAction(type: string): void {
    if (type === 'CANCEL') {
      this.showModal = false
    } else {
      this.controller.delete(this.deletedId)
      this.showModal = false
    }
    this.deletedId = ''
  }

  @Watch('controller.listDataMembershipFormula')
  getDataListMembershipFormula(value: ListMembershipFormula): void {
    if (value.data) {
      this.setDataTable(value.data)
    }
  }

  @Watch('controller.isDeleteLoyaltyProgramMembershipFormulaSuccess')
  onIsDeleteLoyaltyProgramMembershipFormulaSuccessChanged(value: boolean): void {
    if (value) {
      this.controller.getAll(this.params)
    }
  }

  beforeDestroy(): void {
    this.controller.setIsDeleteLoyaltyProgramMembershipFormulaSuccess(false)
  }
}
