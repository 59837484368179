import { DataObject } from "@/app/ui/components/DataTableV2/type"

export enum EnumAction {
  CANCEL = 'CANCEL',
  DELETE = 'DELETE',
  EDIT = 'EDIT'
}

export interface Dropdown {
  label: string
  value: string
}

export interface Status {
  id: number
  status: string
}

export interface DataTable extends Omit<DataObject, 'value'> {
  value?: string | number | Status
}

interface QueryParam {
  page: number
  perPage: number
}

export interface QueryParamsMembership extends QueryParam{
  membershipProgramId: number | null | string
  isActive: boolean
}

export enum EnumStatusFormula {
  UP_COMING = 'Upcoming',
  ON_GOING = 'Ongoing',
  EXPIRED = 'Expired',
  RUNNING = 'Running'
}

export enum EnumLoyaltiProgramChannel {
  CUSTOMER_APPS = 'Customer Apps',
  MARKETPLACE = 'Market Place',
  RETAIL = 'Retail',
  NEW_COD = 'New COD'
}

export enum EnumExtraMultiplierProductType {
  SAMEDAY = 'SAMEDAY',
  BOSSPACK = 'BOSSPACK',
  REGPACK = 'REGPACK',
  JAGOPACK = 'JAGOPACK',
  BIGPACK = 'BIGPACK',
  JUMBOPACK = 'JUMBOPACK',
  INTERPACK = 'INTERPACK',
}
